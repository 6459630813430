import React, { useRef } from "react";
import cls from './FaqItem.module.css';

function FaqItem({ num, header, body, size, isActive, onToggle, index }) {
    const bodyHeight = useRef(null);
    console.log(isActive)
    return (
        <div className={`${cls.item} ${isActive ? cls.active : ''}`} style={{ zIndex: `${size - num + 15}`, marginBottom:`${isActive ? bodyHeight.current.offsetHeight - 20 : 0}px`}}>
            <div className={cls.header}>
                <div className={cls.leftCont}>
                    <span className={cls.num}>{num + 1} </span>
                    <span className={cls.headerText}>{header}</span>
                </div>
                <button className={cls.faqButton} onClick={()=> {onToggle(index)}}>
                    <img className={cls.arrow} src="./images/icons/arrow.svg" alt="" />
                </button>
            </div>
            <div className={cls.back} style={{ zIndex: `${size - num + 1}` }}></div>
            <p className={cls.body} ref={bodyHeight} style={{ zIndex: `${size - num}` }}>{body}</p>
        </div>
    );
}

export default FaqItem;
