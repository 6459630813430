import React, { useContext, useState } from "react";
import cls from './RefModal.module.css';
import { useTranslation } from 'react-i18next';
const tg = window?.Telegram?.WebApp;

function RefModal({visible, setVisible}) {

    const { t } = useTranslation();

    const rootClasses = [cls.main];
    const [messageVis, setMessVisible] = useState('none');


    const link = `https://t.me/NutsCryptoBot?start=${tg?.initDataUnsafe?.user?.id}`; 

    if(visible) {
        rootClasses.push(cls.active);
    }

    const copyToClipboard = () => {
        navigator.clipboard.writeText(link).then(() => {
         
        }, (err) => {
          alert('Ошибка при копировании ссылки: ' + err);
        });
      };

      const sendLink = () => {
        const url = `https://t.me/share/url?url=${encodeURIComponent(link)}`;
        window.open(url, '_blank');
      };

    function close() {
        setVisible(false);
    }

    const modalClasses = [cls.succsesModal]
    function MessVisible() {
      setMessVisible('flex');
      console.log(1234)
        modalClasses.push('vis');
        setTimeout(() => {
          setMessVisible('none');
        }, 3000)
    
    }
    
    return (
      <>
      <div className={modalClasses.join(' ')} style={{display:messageVis}}><img src="images/icons/gal.svg" alt="gal"></img>{t('RefCopedMess')}</div>
      <div className={rootClasses.join(' ')}>
            <div className={cls.content}>
                <h2>{t('inviteFren')}</h2>
                <img 
                onClick={() => close()}
                src="images/icons/close.svg" alt="close"></img>
            <button onClick={() => sendLink()}>{t('Send')}</button>
            <button onClick={() => { MessVisible();copyToClipboard();}}>{t("CopyLink")}</button>
            </div>
        </div>
      </>
        
    );
}

export default RefModal;
