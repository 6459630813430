import React, { useEffect, useState } from "react";
import cls from './taskList.module.css';
import { allTasks, destroyTask } from "../../../http/userAPI";


function AdminTasks({setVisual}) {

    let tasks = [];

    const [taskFilter, setTaskFilter] = useState([...tasks]);

    async function deleteTasks(task_id) {
        await destroyTask(task_id);
        setTaskFilter((prevTasks) => prevTasks.filter((el) => el.id !== task_id));
    }

    async function start() {
        const answer = await allTasks();
        tasks = answer.data.tasks
        console.log(tasks);
        setTaskFilter([...tasks]);
    }

    useEffect( () => {
        start();
    }, [])

    return (
        <div>
          
           <div className={cls.taskContainer}>
           {
            taskFilter.map((el) => {
                return (
                    <div className={cls.task}>
                        <div>
                            {el.image}
                            {el.description}
                        </div>
                        <button onClick={() => {deleteTasks(el.id)}}>destroy</button>
                    </div>
                )
            })
           }
           </div>
        </div>
    );
}

export default AdminTasks;
