import React, { useState } from "react";
import cls from './adminModal.module.css';
import { createTask } from "../../../http/userAPI";

function AdminModal({ visual, setVisual }) {
    const [imagePath, setImagePath] = useState('');
    const [taskName, setTaskName] = useState('');
    const [reward, setReward] = useState('');
    const [link, setLink] = useState('');

    const rootCls = [cls.main];
    
    if (visual) {
        rootCls.push(cls.active);
    }

    function setModal() {
        setVisual(false);
    }

    async function addTask() {
        await createTask(imagePath, taskName, reward, link);
    }

    return (
        <div className={rootCls.join(' ')} onClick={() => setModal()}>
            <div className={cls.body} onClick={(e) => e.stopPropagation()}>
                <input 
                    value={imagePath} 
                    onChange={(e) => setImagePath(e.target.value)} 
                    placeholder={'путь к изображению'} 
                />
                <input 
                    value={taskName} 
                    onChange={(e) => setTaskName(e.target.value)} 
                    placeholder="Название задачи" 
                />
                <input 
                    value={reward} 
                    onChange={(e) => setReward(e.target.value)} 
                    placeholder="награда" 
                />
                <input 
                    value={link} 
                    onChange={(e) => setLink(e.target.value)} 
                    placeholder="ссылка" 
                />

                <button onClick={() => addTask()}>добавить</button>
            </div>
        </div>
    );
}

export default AdminModal;
