import React from "react";
import AdminPannel from "../components/adminPanel/Admin";

function Admin() {

  return (
 <>
    <AdminPannel></AdminPannel>
 </>
  );
}

export default Admin;
