
import React, { useEffect, useRef, useState, useContext } from 'react';
import cls from './Language.module.css';
import { useTranslation } from 'react-i18next';
import { Context } from '../..';

import './style.css'
import { observer } from 'mobx-react-lite';
import { languageChange } from '../../http/userAPI';
const languages = [
    '', '','','','',
    'English', 'Russian', 'Hindy', 'German',
    '', '','','',''
];

const languageShortNames = [
    '', '','','','',
    'en', 'ru', 'hi', 'de',
    '', '','','',''
];
function getCurrentLang(user) {
  const index = languageShortNames.findIndex(el => 
    el === user.language
  );
  return index === -1 ? 1 : index - 5; 
}

const LanguageModal = observer(({ visible, setVisible }) => {

    const {user} = useContext(Context);
    let isTake = false;

    const { i18n } = useTranslation();

    let activeLanguage = '';

    const [activeIndex, setActiveIndex] = useState(0);
    const elementRef = useRef(null);
    const [currentLang, setLanguage] = useState(user.currentLang)

    const listRef = useRef(null);

    async function setCurrentLanguage() {
      const list = listRef.current;
      const items = list.querySelectorAll('.language-item');
      items.forEach((el) => {
        el.classList.remove('currentLanguage')
      })

      await languageChange(user.id, languageShortNames[currentLang]);
      setLanguage(languageShortNames[currentLang]);
      console.log(languageShortNames[currentLang]);
      i18n.changeLanguage(languageShortNames[currentLang]);
      user.setLaguage((languageShortNames[currentLang]));
      user.setCurrentLang(currentLang);
      setVisible(false);
    }


    const updateStyles = () => {
      const list = listRef.current;
      const items = list.querySelectorAll('.language-item');
      let closestItem = null;
      let closestDistance = Infinity;
      let cIndex = null;
    
      items.forEach((item, index) => {
        const rect = item.getBoundingClientRect();
        const listRect = list.getBoundingClientRect();
        const distanceFromCenter = Math.abs(rect.top + rect.height / 2 - (listRect.top + listRect.height / 2));
    
        const maxDistance = listRect.height / 2;
        const scale = 1 - (distanceFromCenter / maxDistance) * 0.75;
    
        item.style.transform = `scale(${scale})`;
    
        // Определяем ближайший элемент
        if (distanceFromCenter < closestDistance) {
          closestDistance = distanceFromCenter;
          closestItem = item;
          cIndex = index;
        }
      });
      
     
      items.forEach((item, index) => {
        if (item === closestItem) {
          item.style.color = '#fff';  // Цвет активного элемента
          item.classList.add('active');
          
          if(!isTake && index !== 0 ) {
              item.classList.add('currentLanguage');
              isTake = true
            
          }
          
        } else {
          item.style.color = '#8EBF8B';  // Цвет всех остальных элементов
          item.classList.remove('active');
        }
      });
    
      if (closestItem) {

        setLanguage(cIndex)
      }
      if(closestItem === items[0]) {
        items[5].style.color = '#fff'; 
        items[5].classList.add('active');
      
      }
    };
    
    

  useEffect(() => {

   
        
   
    const list = listRef.current;
    list.addEventListener('scroll', updateStyles);
    updateStyles(); // Initial update
    console.log(user.language);
    setLanguage(user.language);
    console.log(user.language);
    i18n.changeLanguage(user.language);
  
    return () => {
      list.removeEventListener('scroll', updateStyles);
    };
   
  }, []);


  useEffect(() => {
    if(!visible) return;
    listRef.current.scrollTop = 61 * getCurrentLang(user);
    console.log(listRef.current.scrollTop)
  }, [visible])

  
  useEffect(() => {
    const list = listRef.current;
    const items = list.querySelectorAll('.language-item');

  }, [])

    const rootClasses = [cls.Modal];
    if (visible) {
        rootClasses.push(cls.active);
        
    }

    return (
        <div className={rootClasses.join(' ')} >
            <div className={cls.modalContent} onClick={(e) => e.stopPropagation()}>
                <div className={cls.listCont}>
                  
                    <div className="language-list" ref={listRef}>
                    {languages.map((language, index) => (
                        <div 
                            className={`language-item ${index === user.currentLang ? 'currentLanguage' : ''}`} 
                            key={index}
                            ref={index === activeIndex ? elementRef : null}
                        >
                            {language}
                        </div>
                    ))}
                    </div>
                </div>
                <button className={cls.button} onClick={() => setCurrentLanguage(false)}>Continue</button>
            </div>
        </div> 
    );
});

export default LanguageModal;
