import React, { useState } from "react";
import cls from './Admin.module.css';
import AdminTasks from "./taskList/taskList";
import AdminModal from "./adminModal/adminModal";
import AdminSetter from "./adminSetter/adminSetter";


function AdminPannel() {

    const [showTasks, setShowTasks] = useState(false);
    const [visual, setVisual] = useState(false);
    const [showAdmin, setShowAdmin] = useState(false);

    return (
        <div className={cls.main}>
            <h3>Админ панель</h3>
            <a style={{float:'right'}} href="/home">назад</a>
            <button onClick={() => {setShowTasks(true)}}>посмотреть задачи</button>
            <button onClick={() => {setShowAdmin(!showAdmin)}}>выдать админку</button>
           { showTasks &&
             <>
                <button onClick={() => setVisual(true)}>добавить задачу</button>
                <AdminTasks setVisual={setVisual}></AdminTasks>
             </>
           }
           {
            showAdmin && 
            <AdminSetter></AdminSetter>
           }

           <AdminModal visual={visual} setVisual={setVisual}></AdminModal>
        </div>
    );
}

export default AdminPannel;
