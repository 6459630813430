import { makeAutoObservable } from "mobx";

export default class UserStore {
    constructor() {
        this._isAuth = false;
        this._username = '';
        this._user = {
         
        };
        this._tikets = 0;
        this._coins = 0;
        this._timeFarming = 1;
        this._id = 0;
        this._timeAction = 0;
        this._friendFarm = 0;
        this._Photo = './images/qw.png';
        this._language = 'EN';
        this._isAction = false;
        this._hasAnim = false;
        this._currentLang = 1;
        this._role = 'user';
        makeAutoObservable(this);
    }


    setHasAnim(bool) {
        this._hasAnim = bool;
    }

    setRole(str) {
        this._role = str;
    }

    setCurrentLang(int) {
        this._currentLang = int;
    }

    setUsername(str) {
        this._username = str;
    }
    setIsAuth(bool) {
        this._isAuth = bool;
    }

    setIsAction(bool) {
        this._isAction = bool;
    }

    setLaguage(str) {
        this._language = str;
    }

    setFriendFarme(int) {
        this._friendFarm = int;
    }

    setPhoto(str) {
        this._Photo = str;
    }

    setId(int) {
        this._id = int;
    }

    setCoins(int) {
        this._coins = int;
    }

    setTikets(int) {
        this._tikets = int;
    }

    setUser(user) {
        this._user = user;
    }

    setTime(time) {
        this._timeFarming = time;
    }

    setAction(time) {
        this._timeAction = time;
    }

    get hasAnim() {
        return this._hasAnim;
    }

    get role() {
        return this._role;
    }
    
    get friendFarm() {
        return this._friendFarm
    }

    get username() {
        return this._username;
    }

    get isAction() {
        return this._isAction;
    }

    get language() {
        return this._language;
    }

    get currentLang() {
        return this._currentLang;
    }

    get time() {
        return this._timeFarming;
    }

    get photo() {
        return this._Photo
    }

    get timeAction() {
        return this._timeAction;
    }

    get isAuth() {
        return this._isAuth;
    }

    get id() {
        return this._id;
    }

    get user() {
        return this._user;
    }

    get tikets() {
        return this._tikets;
    }

    get coins() {
        return this._coins;
    }
    dicrimentTikets(int) {
        this._tikets = this._tikets - 1;
    }
} 