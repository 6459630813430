import React, { useState } from "react";
import cls from './FaqList.module.css';
import FaqItem from "../FaqItem/FaqItem";
import { useTranslation } from "react-i18next";

function FaqList() {
    const [activeIndex, setActiveIndex] = useState(null);
    const { t } = useTranslation();

    const list = [
        {
            header:t('Faq.FaqHeaderFir'),
            body:t('Faq.FaqBodyFir')
        },
        {
            header:t('Faq.FaqHeaderSec'),
            body:t('Faq.FaqBodySec')
        },
        {
            header:t('Faq.FaqHeaderThir'),
            body:t('Faq.FaqBodyThir')
        },
        {
            header:t('Faq.FaqHeaderFouth'),
            body:t('Faq.FaqBodyFouth')
        },
        {
            header:t('Faq.FaqHeaderFive'),
            body:t('Faq.FaqBodyFive')
        },
        {
            header:t('Faq.FaqHeaderSix'),
            body:t('Faq.FaqBodySix')
        },
    ];

    const handleToggle = (index) => {
        setActiveIndex(index === activeIndex ? null : index);
    };

    return (
        <div className={cls.list}>
            {list.map((el, index) => {
                return (
                    <FaqItem 
                        key={index}
                        num={index} 
                        header={el.header} 
                        body={el.body} 
                        size={list.length}
                        index={index}
                        isActive={index === activeIndex}
                        onToggle={handleToggle}
                    />
                );
            })}
        </div>
    );
}

export default FaqList;
