import React, { useEffect, useState } from "react";
import cls from './adminSetter.module.css';
import {destroyAdmin, getAdmins, setAdmin } from "../../../http/userAPI";


function AdminSetter({setVisual}) {

    let admins = [];
    const [input, setInput] = useState('');

    const [adminFilter, setAdminFilter] = useState([...admins]);

    async function deleteAdmin(task_id) {
        await destroyAdmin(task_id);
        setAdminFilter((prevTasks) => prevTasks.filter((el) => el.id !== task_id));
    }

    async function start() {
        const answer = await getAdmins();
        admins = answer.data
        console.log(admins);
        setAdminFilter([...admins]);
    }

    useEffect( () => {
        start();
    }, []);

    async function addAdmin(name) {
        await setAdmin(name.target.value);
    }

    return (
        <div>
           
           <div className={cls.taskContainer}>
           {
            adminFilter.map((el) => {
                return (
                    <div className={cls.admin}>
                        <div>
                            {el.photoUrl}
                            {el.username}
                        </div>
                        <button onClick={() => {deleteAdmin(el.id)}}>destroy</button>
                    </div>
                )
            })
           }
           <h2>выдать права администратора</h2>
           <input onChange={(e) => setInput(e)} placeholder="имя пользователя"></input>
           <button onClick={() => {addAdmin(input)}}>создасть</button>
           </div>
        </div>
    );
}

export default AdminSetter;
