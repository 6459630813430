import React, { useEffect, useState, useTransition } from "react";
import cls from './TasksContent.module.css';


import { Link } from "react-router-dom";
import Task from "../Task/Task";
import TaskList from "../TasksList/TasksList";
import { useTranslation } from "react-i18next";

function TaskContent() {

  const { t } = useTranslation();

  useEffect(() => {
    window.history.pushState({ some: 'state' }, '', '/home');
  }, [])
  const [taskCount, setTaskCount] = useState(0);
    return (
        <div className={cls.main}>
          <h1 className={cls.taskHeader}>{t('TaskHeader')} {taskCount}</h1>
          <span className={cls.subheader}>{t('TaskBody')}</span>
          <TaskList setTaskCount={setTaskCount}></TaskList>
        </div>
    );
}

export default TaskContent;
