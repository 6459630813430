import React, { useTransition } from "react";
import cls from './FaqContent.module.css';
import { Link } from "react-router-dom";
import FaqList from "../FaqList/FaqList";
import { useTranslation } from "react-i18next";

function FaqContent({ lang, userPhoto}) {

    const {t} = useTranslation()
   
    return (
        <div className={cls.main} style={{padding:'0 15px'}}>
            <h1 className={cls.header}>{t('FaqHeader')}</h1>
            <FaqList></FaqList>
            
        </div>
    );
}

export default FaqContent;
